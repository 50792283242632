<template lang="pug">
  .topic2-modal
    b-modal(id="topic2-modal", :title="title", size="lg", ok-variant="outline-primary", cancel-variant="link", no-close-on-backdrop, ref="topic2refs",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        b-tabs(content-class="mt-3")
          b-tab(title="Main" active)
            .form-row()

              .col-12 
                b-form-group(id="name-group"  
                            label="Name"
                            size="sm"
                            label-for="name"
                            description="",
                            v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                        b-form-input(id="name"
                                    type="text"
                                    :state="!submitStatus ? null : !$v.form.name.$invalid"
                                    size="sm"
                                    placeholder="", v-model="form.name")
              
              .col-12
                
                  b-form-group(id="name-group"  
                              label="Industry"
                              size="sm"
                              label-for="industy"
                              description="",
                              v-bind:class="{ 'form-group--error': $v.form.name.$error }")
                          TaxonomySelect(topic_type="industry", v-model="form.parent_ids")
                      

              .col-12
                b-form-group(id="description-group"
                            label="Description"
                            label-for="exampleInput1",
                      
                            description="")
                        b-textarea(id="description"
                                    type="text"
                                    size="sm"
                                    placeholder="", @change="", v-model="form.description")
              

             
          b-tab(title="Query")
            .col-12
              b-form-group(id="stages-group"
                            label="Keywords"
                            label-for="exampleInput1"
                            description="").mt-2
                        
                TopicKeywordSelect(id="keyword-select", :show-recommendations="false", v-model="form.full_query", :embed="true") 

              b-form-group(id="stages-group"
                            label="Sources include list"
                            label-for="exampleInput1"
                            description="Sources that are always included for this topic", v-if="sources").mt-2
           
                v-select(:options="sources", :reduce="it => it.name " multiple, :size="3", v-model="form.sources_include", label="name", text-field="name", options-field="_id" )


              b-form-group(id="stages-group"
                            label="Sources exclude list"
                            label-for="exampleInput1"
                            description="Sources that are excluded for this topic", v-if="sources").mt-2
           
                v-select(:options="sources", multiple, :size="3", v-model="form.sources_exclude",  label="name", text-field="name", options-field="_id" )


            //.col-12
              b-form-group(id="check"
                            label="Taggable"
                            label-for="exampleInput1"
                            description="").mt-2
                        
                b-check() News can be tagged with this topic
                b-check() Projects can be tagged with this topic
                b-check() Companies can be tagged with this topic


        
      template(slot="modal-footer")
            i(v-if="project") Add to project "{{project.name}}"
            b-btn(variant="outline-secondary" @click="$refs['topic2refs'].hide(false)") Close
            b-btn(variant="primary"  @click="save()")#project-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import ProjectStageSelect from '@/components/ProjectStageSelect'

import TaxonomySelect from '@/components/TaxonomySelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'Topic2Modal',
  props: { 
            "keywords":  Array, 
          },
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        project: null,
        uid: null,
        title: "Add a new topic",
        form: {},
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Topic",
        sources: null
        
    }
  },

  mounted: function() {
     this.initiateComponent();
  },

  methods: {
    initiateComponent(){
        this.uid = this._uid;
    },

    open(topic, project = null) {

      Vue.http.get(`${Config.config.base_host}/topics_v1/sources`, this.form).then((res) => {
      
          this.sources = this.$_.orderBy(res.body, ['name'], ['asc']);
      
      });

      if(project) {
        this.project = project;
      }

      if(topic) {
        console.log(topic);
        this.form = JSON.parse(JSON.stringify(topic));
        this.ok_btn_text = "Save";
        if(topic.id) {
          this.title = "Edit topic";
        } else {
          this.title = "Add a new topic";
        }
     
        this.form.topic_id = this.$route.params.id;
      } else  {
        this.title = "New topic"
        this.form = {"description": "", "name": "", parent_ids: [], keywords: [], "topic_id": this.$route.params.id, "sources_include": [], "sources_exclude": []};
      }
      if( this.keywords ){
        this.form.keywords = this.$_.cloneDeep(  this.keywords );
      }
      

      this.$refs['topic2refs'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        
        if(this.form._id){
          Vue.http.put(`${Config.config.base_host}/topics_v1/${this.form._id}`, this.form).then(() => {
        
            location.reload();
          });
        } else {
          if(this.project) this.form.project_id = this.project._id;
          if(!this.form.topic_type) this.form.topic_type = "topic";
          Vue.http.post(`${Config.config.base_host}/topics_v1`, this.form).then(( res ) => {
         
            location.href = "/topics/beta_details/" + res.body._id;
          });
        }
        
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect,
    TaxonomySelect,
    ProjectStageSelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
