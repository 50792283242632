  <template lang="pug">
  
  .filter-group

    div(v-show="options.length > 0")
        v-select(@focus.native="cleanSearch()", @click.native="cleanSearch()", :selectable="option => internal_value ? !internal_value.includes(option._id) : true", :taggable="false", :id="id", :multiple="multiple", :disabled="disabled", label="name", v-model="internal_value" :options="options", :filterable="true", :reduce=" x => x._id", :close-on-select="closeOnSelect", :select-on-tab="true")
            //template(slot="no-options") Type to search ...
            template(#no-options="{ search, searching, loading }") Nothing found
        //b-form-tags(
            input-id="tags-separators"
            size="sm"
            v-model="value"
            separator=","
            placeholder="")

    template(v-if="options.length == 0")
      v-select(placeholder="Loading ...", :disabled="true")

                 
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'

export default {
  name: 'UserMultiSelect',
  props: {
    "topic_type": {
        default: null
    },
    "show_type": {
        type: Boolean,
       default: false
    },
    "value":  {
      default: null
    }, 
    "id":  {
      default: "UserMultiSelect"
    }, 
    "collpased": {
      type: Boolean,
      default: false
    },
    "closeOnSelect": {
      type: Boolean,
      default: true
    },
    "multiple": {
      type: Boolean,
      default: true
    },
    "disabled": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: [],
        internal_value: null
       
    }
  },

  watch: {
        internal_value: function(val, oldVal) {
          console.log(val, oldVal);
          if(val != oldVal && val) this.$emit("input", val);
        }
  },

  beforeMount: function() {
    this.internal_value = this.value;
  },

  mounted: function() {
     
     this.uid = this._uid;
     this.cleanSearch();
  },

  computed: {

  },

  methods: {
    change(event) {
      alert(event);
    },

    cleanSearch() {
        let vm = this;

         if(this.$store.state.topic_type_cache[this.topic_type]) {
         
           
                vm.options = this.$store.state.topic_type_cache[this.topic_type];
             
   
        } else {

          Vue.http.get(`${Config.config.base_host}/topics_v1?topic_type=${escape(this.topic_type)}`).then(res => {
              this.$store.state.topic_type_cache[this.topic_type] = res.body;
             
               vm.options = res.body;
         
                
          });
        }

    },
    onSearch(search, loading) {
        loading(true);
        this.search(search, loading, this);
    },

    search: debounce((search, loading, vm) => {
      loading(true);
      Vue.http.get(`${Config.config.base_host}/topics_v1?name=${escape(search)}&topic_type=${escape(vm.topic_type)}`).then(res => {
        if(vm.show_type) {
          
          let e = [];
          res.body.forEach((x) => {
        
            x['name'] = x['name'] + ' - ' + x['topic_type'];
            e.push(x);
            console.log(x);
          });
          vm.options = e;
        } else {
          vm.options = res.body;
        }
        
        loading(false);
      });

    }, 350)
  },

  components: {
    
  }
}

</script>
<style lang="scss">

</style>
